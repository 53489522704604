@font-face {
  font-family: "URW DIN";
  src: local("URW"), url("./fonts/URW/URWDIN-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "URW DIN REGULAR";
  src: local("URW"), url("./fonts/URW/URWDIN-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "URW DIN MEDIUM";
  src: local("URW"), url("./fonts/URW/URWDIN-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "URW DIN DEMI";
  src: local("URW"), url("./fonts/URW/URWDIN-Demi.ttf") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;500;600&display=swap");
body {
  margin: 0;
  font-family: "URW DIN";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  padding: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: "URW DIN";
}

body {
  background-color: rgb(255, 255, 255);
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

@-webkit-keyframes slowly-come-up {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slowly-come-up {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slowly-come-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes slowly-come-down {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
  }
}

.show {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.bottom-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 8vh;
  opacity: 0;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  -webkit-animation: slowly-come-down 0.5s ease-in-out;
  animation: slowly-come-down 0.5s ease-in-out;

  animation-delay: 5s;
  -webkit-transition-delay: 5s;
  -moz-transition-delay: 5s;
  -o-transition-delay: 5s;
  transition-delay: 5s;
}

.bottom-menu-area:hover .bottom-menu {
  opacity: 1;
  -webkit-animation: slowly-come-up 0.5s ease-in-out;
  animation: slowly-come-up 0.5s ease-in-out;
}

.Authbg {
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: -12;
}
.Authlogo {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 3vh;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.container .eventImg {
  height: 150px;
  width: 150px;
  margin: 18px 2px 0px 2px;
  object-fit: cover;
}
.overlay {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 10%;
  height: 160px;
  width: 160px;
  opacity: 0;
  /* outline: 12px solid #0A4777; */
  border-radius: 50%;
  transition: 0s ease;
  background-color: rgba(0, 205, 184, 1);
  cursor: pointer;
}

.container .eventList {
  width: 22%;
}
.container .eventList:hover {
  background-color: #0e558c !important;
}

.container .eventList:hover .overlay {
  opacity: 1;
}

.overlay .joinnow {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.eventGridView .overlayEvent {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 9%;
  height: 142px;
  width: 142px;
  opacity: 0;
  border-radius: 50%;
  transition: 0s ease;
  background-color: rgba(0, 205, 184, 1);
  cursor: pointer;
}
.eventGridView .eventGrid {
  background-color: #012243;
  width: 18%;
}
.eventGridView .eventGrid:hover {
  background-color: #0e558c;
}

.eventGridView .eventGrid.active {
  background-color: #0e558c;
}
.eventGridView .active .overlayEvent {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 9%;
  height: 142px;
  width: 142px;
  opacity: 1;
  border-radius: 50%;
  transition: 0s ease;
  background-color: #008bcd;
  cursor: pointer;
}

.eventGridView .eventGrid:hover .overlayEvent {
  opacity: 1;
}
.moreGridButton {
  background-color: #143f63;
}
.moreGridButton:hover {
  background-color: #008bcd;
}
.eventGridView .active .moreGridButton {
  background-color: #008bcd;
}
.eventGridView .active .dotIcon {
  filter: brightness(0) invert(1);
}
.moreGridButton:hover .dotIcon {
  filter: brightness(0) invert(1);
}

.overlayEvent .joinnow {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.css-1ws8119-MuiDivider-root::before,
.css-1ws8119-MuiDivider-root::after {
  border-top: 2px solid #012a50 !important;
  content: "";
}
.sortby_space:hover {
  background-color: #143f63 !important;
}

@media only screen and (min-width: 600px) {
  .login-form {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .lobyRightside {
    position: relative;
  }
  .lobyRightside .eventHeading {
    position: absolute;
    top: 26vh;
    left: 50%;
    transform: translateX(-50%);
  }

  .lobyRightside .countDownDes {
    position: absolute;
    top: 70vh;
    left: 50%;
    transform: translateX(-50%);
  }
}
.header-bell-icon {
  margin-right: 30px;
}
.header-profile-pic {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.header-profile-name {
  color: #88a1ab;
  margin-left: 10px;
  line-height: 24px;
}
/* .overlay-bg{
    position: relative;
    
  }
  .overlay-bg:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(3, 46, 87, 0.95);
  } */
.group-img {
  height: 93.8vh;
  width: 100%;
  object-fit: cover;
}
.settings-bg .settings-container {
  /* position: absolute; */
  margin: 0 auto;
  background: #012243;
  border-radius: 4px;
  width: 700px;
  /* height: 470px; */
}

/* .settings-container .settings-navbar:hover{
    border-bottom: 2px solid #008BCD;
    border-radius: 0px;
    background-color: transparent;
  } */

.filemanager-popup {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99;
}
.filemanager-popup .container {
  width: 1140px;
  text-align: center;
}
.profile-popup .profile-box {
  width: 456px;
  height: 400px;
}
.profile-box .innerbox {
  width: 416px;
  height: 276px;
}

.profile-popup .close-button {
  background: #5c7189;
}
.profile-popup .close-button:hover {
  background: #94989d;
  cursor: pointer;
}
.update-container {
  width: 482px;
  height: 751px;
}
.update-container .active {
  border-bottom: 2px solid #008bcd;
  color: #008bcd !important;
}
.update-container .content {
  display: none;
}
.update-container .active-content {
  display: block;
}
/* .testclass:before {
    content: "";
    position: absolute;
    left: 0; right: 0;
    top: 0; bottom: 0;
    background: rgba(1, 34, 67, .95);
  } */
.eventWaiting .eventImg {
  width: 250px;
  height: 250px;
}
.eventWaiting .eventHeading {
  font-size: 40px;
}
.eventWaiting .eventCountDown {
  padding: 27px 33px 21px;
  margin-left: 25px;
}
.eventCountDown .CountDown {
  font-size: 90px;
  font-family: "URW DIN";
}
.eventCountDown .CountDownHeading {
  font-size: 30px;
  margin-top: -22px;
}
.eventWaiting .countDownDes {
  font-size: 24px;
}
.flex {
  align-items: center;
  display: flex;
  text-align: center;
}
.flex.flex-wrap {
  flex-wrap: wrap;
}
.gap {
  gap: 40px;
}
.gap-20 {
  gap: 20px;
}
.w18 {
  width: 18%;
}

.w10 {
  width: 10.4%;
}

.tableCell .viewButton {
  background-color: #143f63;
}
#new-window-container .controlPanel .tableCell:hover {
  background-color: #143f63;
  border-radius: 4px;
}

#new-window-container .controlPanel .tableCell:hover .viewButton {
  background-color: #012a50;
}

.tableCell select {
  /* styling */
  /* background-color: white;
  border: thin solid blue; */
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.input:focus {
  outline: none !important;
  border: 1px solid red;
  box-shadow: 0 0 10px #719ece;
}

.tableCell select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, #88a1ab 50%),
    linear-gradient(135deg, #88a1ab 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 0px 1.5em;
  background-repeat: no-repeat;
}
.tableCell select.minimal option {
  padding: 20px;
}

.tableCell select.minimal:focus {
  background-image: linear-gradient(45deg, #88a1ab 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #88a1ab 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 0px 1.5em;
  background-repeat: no-repeat;
  /* border-color: green; */
  outline: 0;
}

.tableCell select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.filemanager-popup .filemanager-grid:hover {
  outline: 4px solid #008bcd;
}
.filemanager-popup .filemanager-grid.active {
  outline: 3px solid #008bcd;
}

.filemanager-popup .content {
  display: none;
}
.filemanager-popup .active-content {
  display: block;
}
.filemanager-popup .active {
  border-bottom: 2px solid #008bcd;
  color: #008bcd !important;
}
.screen .screen-box {
  outline: 2px solid #012a50;
}
.screen .screen-box:hover {
  outline: 2px solid #008bcd;
  background-color: #012a50;
}
.lobyRightside .eventCountDown {
  /* padding: 40px; */
  width: 168px;
  height: 189px;
}
.lobyRightside .eventCoutdowndiv {
  gap: 25px;
}

/* Event List View */

.eventListView .tableCell:hover {
  /* background-color: rgb(27 69 103); */
  border-radius: 4;
}

.eventListView .tableCell:hover .viewButton {
  background-color: #012a50;
}

/* .eventListView .tableCell:hover .checkbox{
  color: #012A50;
} */

.eventListView .tableCell:hover .linkIcon {
  filter: brightness(0) saturate(100%) invert(13%) sepia(26%) saturate(4023%)
    hue-rotate(187deg) brightness(96%) contrast(103%);
}
.eventListView .tableCell:hover .editIcon {
  filter: brightness(0) saturate(100%) invert(13%) sepia(26%) saturate(4023%)
    hue-rotate(187deg) brightness(96%) contrast(103%);
}
.eventListView .tableCell:hover .closeIcon {
  filter: brightness(0) saturate(100%) invert(13%) sepia(26%) saturate(4023%)
    hue-rotate(187deg) brightness(96%) contrast(103%);
}
.eventListView .tableCell .closeIcon:hover {
  filter: invert(34%) sepia(97%) saturate(6062%) hue-rotate(332deg)
    brightness(99%) contrast(104%);
}
.eventListView .tableCell .linkIcon:hover {
  filter: invert(50%) sepia(84%) saturate(4942%) hue-rotate(176deg)
    brightness(93%) contrast(101%);
}
.eventListView .tableCell .editIcon:hover {
  filter: invert(50%) sepia(84%) saturate(4942%) hue-rotate(176deg)
    brightness(93%) contrast(101%);
}


.editIconParent .editIcon{
 
  display:none;
}
.editIconParent:hover .editIcon{
  filter: invert(50%) sepia(84%) saturate(4942%) hue-rotate(176deg)
  brightness(93%) contrast(101%);
  display:block
}
/* .eventListView .tableCell .joinNow{
  background-color: #143F63;
}

.eventListView .tableCell .joinNow:hover{
  background-color: #008BCD;

} */

.eventListView .collapseRow .linkIcon:hover {
  filter: invert(50%) sepia(84%) saturate(4942%) hue-rotate(176deg)
    brightness(93%) contrast(101%);
}
.eventListView .collapseRow .editIcon:hover {
  filter: invert(50%) sepia(84%) saturate(4942%) hue-rotate(176deg)
    brightness(93%) contrast(101%);
}
.eventListView .collapseRow .closeIcon:hover {
  filter: invert(34%) sepia(97%) saturate(6062%) hue-rotate(332deg)
    brightness(99%) contrast(104%);
}
.userListView .collapseRow .closeIcon:hover {
  filter: invert(34%) sepia(97%) saturate(6062%) hue-rotate(332deg)
    brightness(99%) contrast(104%);
}

/* .eventListView .collapseRow .joinNow{
  background-color: #143F63;
}

.eventListView .collapseRow .joinNow:hover{
  background-color: #008BCD;

} */

@media only screen and (max-width: 600px) {
  .Authlogo {
    width: 200px;
    top: 8vh;
  }
  .login-form {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
  .login-form .login-form-box {
    width: 300px;
  }
  /* .login-form {
      position:absolute;
      left:0;
      right:0;
      margin-left:auto;
      margin-right:auto;
      top: 30%;
  } */
  .header-bell-icon {
    margin-right: 10px;
  }
  .header-profile-name {
    display: none;
  }
  .overlay-bg .settings-container {
    width: 350px;
    /* top: 20%; */
  }
  .filemanager-popup .container {
    width: 360px;
  }

  .profile-popup .profile-box {
    width: 340px;
    height: 440px;
  }
  .profile-box .innerbox {
    width: 298px;
    height: 320px;
  }
  .update-container {
    width: 340px;
    height: 751px;
  }
  .eventWaiting .eventImg {
    width: 175px;
    height: 175px;
  }
  .eventWaiting .eventHeading {
    font-size: 16px;
  }
  .eventWaiting .eventCountDown {
    padding: 10px;
    margin-left: 15px;
  }
  .eventCountDown .CountDown {
    font-size: 30px;
    font-family: "URW DIN";
  }
  .eventCountDown .CountDownHeading {
    font-size: 16px;
    margin-top: -14px;
  }
  .eventWaiting .countDownDes {
    font-size: 16px;
  }
  .container .eventList {
    width: 100%;
    margin-right: 55px;
  }
  .w18 {
    width: 100%;
    margin-right: 21px;
  }

  .w10 {
    width: 44%;
  }

  .lobyRightside {
    flex-direction: column !important;
  }

  .lobyRightside .eventCountDown {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
  }
  .lobyRightside .eventCoutdowndiv {
    gap: 10px;
    margin-top: 20px;
  }
  .lobyRightside .eventHeading {
    margin-top: 30px;
    font-size: 20px;
  }
  .lobyRightside .countDownDes {
    margin-bottom: 30px;
    /* margin-top: -10px; */
    font-size: 12px;
  }
}

@media only screen and (max-width: 280px) {
  .Authlogo {
    width: 150px;
    top: 6vh;
  }
  .login-form .login-form-box {
    width: 210px;
  }
  .login-form-box .block {
    display: block;
    text-align: center;
  }
  .container .eventList {
    margin-right: 10px;
    margin-left: -20px;
  }

  .lobyRightside .eventCountDown {
    width: 45px;
    height: 50px;
    margin-bottom: 25px;
    margin-top: 25px;
  }
  .lobyRightside .eventCoutdowndiv {
    gap: 10px;
  }
  .eventCountDown .CountDown {
    font-size: 20px;
    font-family: "URW DIN";
  }
  .eventCountDown .CountDownHeading {
    font-size: 10px;
    margin-top: -4px;
  }
}

@media (min-width: 600px) and (max-width: 950px) {
  .container .eventList {
    width: 42%;
  }
  .w18 {
    width: 30%;
  }

  .w10 {
    width: 18%;
  }

  .eventGridView .eventGrid {
    width: 22%;
  }
}

@media (min-width: 600px) and (max-width: 768px) {
  .lobyRightside .eventCountDown {
    width: 80px;
    height: 80px;
  }
  .lobyRightside .eventCoutdowndiv {
    gap: 10px;
    margin-top: 20px;
  }
  .eventCountDown .CountDown {
    font-size: 30px;
    font-family: "URW DIN";
  }
  .eventCountDown .CountDownHeading {
    font-size: 16px;
    margin-top: -8px;
  }
  .eventGridView .eventGrid {
    width: 22%;
  }
  .w10 {
    width: 25%;
  }
  .w18 {
    width: 40%;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .container .eventList {
    width: 29%;
  }
  .w10 {
    width: 18%;
  }
  .w18 {
    width: 35%;
  }
  .lobyRightside .eventCountDown {
    /*      
  padding: 25px; */
    width: 100px;
    height: 105px;
  }
  .lobyRightside .eventCoutdowndiv {
    gap: 10px;
    margin-top: 20px;
  }
  .eventCountDown .CountDown {
    font-size: 50px;
    font-family: "URW DIN";
  }
  .eventCountDown .CountDownHeading {
    font-size: 18px;
    margin-top: -14px;
  }
  .eventGridView .eventGrid {
    width: 22%;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .w18 {
    width: 25%;
  }

  .w10 {
    width: 13%;
  }
  .eventGridView .eventGrid {
    width: 22%;
  }
}

.content-full-height {
  height: 100vh;
  margin: 0px;
  padding: 0px;
}
/* Hide scrollbar for Chrome, Safari and Opera */

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none;
}
.fullscreen {
  width: 100vw;
  height: calc(94vh - 10px);
  background-color: rgb(3, 46, 87);
}

.vertical-scrollbar::-webkit-scrollbar {
  width: 10px;
}
.vertical-scrollbar::-webkit-scrollbar-track {
  width: 10px;
}
.vertical-scrollbar::-webkit-scrollbar-thumb {
  background: #88a1ab;
  border-radius: 24px;
}

.loader-container {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid;
  /* border-color: #E1E7EA transparent; */
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-thumb {
  background-color: #011934;
  background-clip: padding-box;
  /* border: 2px solid #fff; */
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: inset -1px 1px 0 #011934, inset 1px 1px 0 #011934;
}
::-webkit-scrollbar-thumb:hover {
  background: #061b33;
}

/* scrollbar for avatar */

.avatar-scroll::-webkit-scrollbar {
  width: 6px;
}
.avatar-scroll::-webkit-scrollbar-thumb {
  background-color: '#012A50';
  background-clip: padding-box;
  /* border: 2px solid #fff; */
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-box-shadow: inset -1px 1px 0 #012A50, inset 1px 1px 0 #012A50;
}
.avatar-scroll::-webkit-scrollbar-thumb:hover {
  background: #012A50;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #5d7c90;
  /* padding: 0px 0px 0px 10px;
  
  width: 100%;
  -webkit-padding-start: 10px;
  -webkit-box-shadow: 0 0 0px 1000px #011934 inset; */

  border: 0px solid green;
  outline: 0px solid green;
  /* height: 42px; */

  /* -webkit-text-fill-color: green; */
  /* -webkit-box-shadow: 0 0 0px 1000px #011934 inset; */
  transition: background-color 5000s ease-in-out 0s;
}
@keyframes blinkingText {
  0% {
    background-color: yellow;
  }
  100% {
    background-color: #1056c0;
  }
}
/* Flickering animation */
@keyframes flicker {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    box-shadow: 0 0 4px var(--ring-color-2),
      0 0 5px var(--ring-color-3), 0 0 10px var(--ring-color-4);
    /* 0 0 80px hsl(199, 93%, 20%), 0 0 90px hsl(199, 93%, 15%),
      0 0 100px hsl(199, 93%, 10%), 0 0 150px hsl(199, 93%, 5%); */
  }

  20%,
  24%,
  55% {
    box-shadow: none;
  }
}
.flicker {
  animation: flicker 1.5s infinite alternate;
  border: 2px solid var(--ring-color);
}

@keyframes flickerVideo {
  0%,
  18%,
  22%,
  25%,
  53%,
  57%,
  100% {
    box-shadow: 0 0 2px var(--ring-color-2),
      0 0 6px var(--ring-color-3), 0 0 10px var(--ring-color-4);
  }

  20%,
  24%,
  55% {
    box-shadow: none;
  }
}
.flickerVideo {
  animation: flickerVideo 1.5s infinite alternate;
  outline: 2px solid var(--ring-color);
  /* border: 1px solid var(--ring-color); */

}
.bottom-menu-area-flash {
  /* background-color: yellow; */
  animation: blinkingText 2s infinite;
}

.fullscreen {
  gap: 10px;
}

.fullscreen2 {
  width: 100vw;
  height: 100%;
  background-color: rgb(86, 87, 3);
  gap: 10px;
}

/* .role .roleNav {
  border-bottom: 2px solid #008bcd;
  margin-bottom: -2px;
} */

.treeview {
  float: left;
  width: 89%;
}
.treeview ul {
  float: left;
  width: 100%;
  list-style: none;
  /* position: relative; */
  margin-top: -33px;
}

/* .treeview ul li.first {
  float: left;
  width: 100%;
  border-left: 2px solid #88A1AB;
  padding: 2px 0px 8px;
} */
.treeview ul li {
  float: left;
  width: 100%;
  font-size: 15px;
  color: #88a1ab;
  line-height: 1.5;
  padding-left: 50px;
  position: relative;
  bottom: -20px;
  /* border-left: 2px solid #88A1AB; */
}
.treeview ul li:after {
  content: "";
  height: 100%;
  width: 2px;

  position: absolute;
  left: 16px;
  top: 0;
  /* height: 50%; */

  background-color: #88a1ab;
}
.treeview ul li:last-child:after {
  content: "";
  height: 100%;
  width: 2px;

  position: absolute;
  left: 16px;
  top: 0;
  height: 50%;

  background-color: #88a1ab;
}


.treeview ul .line {
  margin-top: 14px;
}
.treeview ul li:last-child .line {
  margin-bottom: 14px;
}

.treeview ul li:before {
  content: "";
  width: 38px;
  height: 2px;
  background-color: #88a1ab;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 16px;
}

.treeview .PermissionGroupDropdown {
  position: absolute;
  /* top: 50%; */
  right: 20%;
  /* transform: translateX(-50%) translateY(-50%); */
}

@media (min-width: 1600px) {
  .treeview {
    float: left;
    width: 91%;
  }
  .treeview .PermissionGroupDropdown {
    position: absolute;
    /* top: 50%; */
    right: 22%;
    /* transform: translateX(-50%) translateY(-50%); */
  }
}
@media (min-width: 1719px) {
  .treeview {
    float: left;
    width: 92%;
  }
  .treeview .PermissionGroupDropdown {
    position: absolute;
    /* top: 50%; */
    right: 22%;
    /* transform: translateX(-50%) translateY(-50%); */
  }
}
@media (min-width: 1900px) {
  .treeview {
    float: left;
    width: 93%;
  }
  .treeview .PermissionGroupDropdown {
    position: absolute;
    /* top: 50%; */
    right: 23%;
    /* transform: translateX(-50%) translateY(-50%); */
  }
}
@media (min-width: 2085px) {
  .treeview {
    float: left;
    width: 94%;
  }
  .treeview .PermissionGroupDropdown {
    position: absolute;
    /* top: 50%; */
    right: 23%;
    /* transform: translateX(-50%) translateY(-50%); */
  }
}

::-webkit-file-upload-button {
  cursor: pointer;
}

/* .tableFixHead {
  width: 600px;
  table-layout: fixed;
  border-collapse: collapse;
}
  .tableFixHead tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 150px;
}
.tableFixHead thead tr {
  display: block;
}
.tableFixHead th,
.tableFixHead  td {
  padding: 5px 10px;
  width: 600px;
} */

.dropdown input {
  display: none;
}

.dropdown label {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown label span {
  position: relative;
  line-height: 20px;
}

.dropdown label span:before,
label span:after {
  content: "";
}

.dropdown label span:before {
  border: 2px solid #002e56;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
}

.dropdown label span:after {
  background: #008bcd;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 5px;
  transition: 300ms;
  opacity: 0;
}

.dropdown label input:checked + span:after {
  opacity: 1;
}

/* dropdown */

.dropdown {
  margin: 0 auto;
  width: 108px;
  position: relative;
  display: inline-block;
}

.dropdown .dropdown-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.dropdown-content {
  position: absolute;

  padding: 11px 0px;
  margin-top: 5px;
  left: 0;
  width: 150%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 16;
}


.dropdown-content .item {
  padding: 4px 10px;
  cursor: pointer;
  text-align: left;
}

/* subroom list dropd down */

/* .dropdown-subroom{
  position: relative;
}

.dropdown-subroom-content {
  position: absolute;

  padding: 11px 0px;
  margin-top: 5px;
  left: 0;
  width: 150%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 1;
}

.dropdown-subroom-content .item {
  padding: 4px 10px;
  cursor: pointer;
  text-align: left;
} */
/* .dropdown-content .item:hover {
  background: #fcfcfc;
} */

.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}
.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

.form-group input[type="radio"] {
  height: 16px;
  width: 16px;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  border: 2px solid #143f63;
  border-radius: 3px;
  background: #002e56;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.form-group input[type="radio"]:hover {
  height: 16px;
  width: 16px;
  margin: 0;
  padding: 0;
  opacity: 1;
  appearance: none;
  border: 2px solid #012243;
  border-radius: 3px;
  background: #143f63;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}

.form-group input[type="radio"]:checked {
  border: 2px solid #008bcd;
  background: #008bcd;
}

.form-group input[type="radio"]:checked:before,
input[type="radio"]:checked:after {
  content: "";
  position: absolute;
  height: 2px;
  background: #fff;
}

.form-group input[type="radio"]:checked:before {
  width: 5px;
  top: 6px;
  left: 1px;
  transform: rotate(44deg);
}

.form-group input[type="radio"]:checked:after {
  width: 9px;
  top: 5px;
  left: 3px;
  transform: rotate(-55deg);
}

/* Loby custom radio button */

.isLobby input {
  display: none;
}

.isLobby label {
  display: inline-block;
  padding: 8px 10px;
  cursor: pointer;
}
.isLobby label span {
  position: relative;
  line-height: 22px;
}

.isLobby label span:before,
.isLobby label span:after {
  content: "";
}
.isLobby label span:before {
  border: 1px solid #008bcd;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  border-radius: 2px;
}

.isLobby label span:after {
  background: #008bcd;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 0px;
  left: 4px;
  transition: 300ms;
  opacity: 0;
}
.isLobby label input:checked + span:after {
  opacity: 1;
}

/* prevent text selectiion */

.treeviewRole {
  float: left;
  width: 92%;
  margin-top: 9px;
  margin-bottom: 15px;
}
.treeviewRole ul {
  float: left;
  width: 225px;
  list-style: none;
  /* position: relative; */
  margin-top: -33px;
}

.treeviewRole ul li {
  float: left;
  width: 100%;
  font-size: 15px;
  color: #88a1ab;
  line-height: 1.5;
  padding-left: 24px;
  position: relative;
  bottom: -20px;
  top: 34px;
  left: 10px;
  height: 50px;
  margin-top: -5px;
  /* border-left: 2px solid #88A1AB; */
}
.treeviewRole ul li:after {
  content: "";
  height: 100%;
  width: 2px;

  position: absolute;
  left: 0;
  top: 0;
  /* height: 50%; */

  background-color: #88a1ab;
}
.treeviewRole ul li:first-child:after {
  content: "";
  height: 100%;
  width: 2px;

  position: absolute;
  left: 0;
  top: -4px;
  /* height: 50%; */

  background-color: #88a1ab;
}
.treeviewRole ul li:last-child:after {
  content: "";
  height: 100%;
  width: 2px;

  position: absolute;
  left: 0;
  top: 0;
  height: 50%;

  background-color: #88a1ab;
}

/* .treeviewRole ul .line{
  margin-top:9px,

}  */
/* .treeviewRole ul li:last-child{
  height: 35px;
} */
.treeviewRole ul li:last-child .line {
  margin-bottom: 14px;
}

.treeviewRole ul li:before {
  content: "";
  width: 24px;
  height: 2px;
  background-color: #88a1ab;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
}
/* .treeviewRole ul li.viewer:before {
  content: "";
  width: 24px;
  height: 2px;
  background-color: #88a1ab;
  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 0;
} */
.treeviewRole ul li.viewer:after {
  content: "";
  height: 100%;
  width: 2px;

  position: absolute;
  left: 0px;
  top: 0;
  height: 50%;
  background-color: #88a1ab;
}

.dropdownRole label {
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
}

.dropdownRole label span {
  position: relative;
  line-height: 20px;
}

.dropdownRole label span:before,
label span:after {
  content: "";
}

.dropdownRole label span:before {
  border: 2px solid #002e56;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  display: inline-block;
  vertical-align: top;
  border-radius: 4px;
}

.dropdownRole label span:after {
  background: #008bcd;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 2px;
  left: 5px;
  transition: 300ms;
  opacity: 0;
}

.dropdownRole label input:checked + span:after {
  opacity: 1;
}

/* dropdown */

.dropdownRole {
  margin: 0 auto;
  width: 130px;
  position: relative;
  display: inline-block;
}

.dropdownRole .dropdown-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}
.dropdownRole-content {
  position: absolute;

  padding: 0px 0px 8px;
  margin-top: 5px;
  left: 0;
  width: 318px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 1;
}

.dropdownRole-content .item {
  padding: 3px 10px;
  cursor: pointer;
  text-align: left;
  /* min-height: 91px; */
  /* width: 100%; */
}
/* .dropdown-content .item:hover {
  background: #fcfcfc;
} */

.InvitePeopleDrop {
  position: relative;
}
.InvitePeople .dropdown-contents {
  position: absolute;
  padding: 15px 0px;
  margin-top: 5px;
  /* right: 168px; */
  left:40px;
  width: 306px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
  z-index: 1;
}
.dropdown-contents .item {
  padding: 5px 22px;
  cursor: pointer;
}

.dropdownEvent {
  margin: 0 auto;

  width: 257px;
  position: relative;
}

.dropdownEvent .dropdown-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}
.dropdown-event-content {
  position: absolute;
  /* top: 110%; */
  z-index: 10;

  left: 0;
  width: 100%;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}
.dropdown-event-content .item {
  padding: 4px 10px;
  cursor: pointer;
  text-align: left;
}
/* .dropdown-event-content .item:hover {
  background: #143F63;
} */

/* invite member */

.tableFixHead .invite-icon:hover {
  cursor: pointer;
  filter: invert(34%) sepia(97%) saturate(6062%) hue-rotate(332deg)
    brightness(99%) contrast(104%);
}

.tableFixHead .invite-icon-add:hover {
  cursor: pointer;
  filter: invert(36%) sepia(91%) saturate(748%) hue-rotate(163deg) brightness(100%) contrast(107%);
}

/* loader */
.spinner-square {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 120px;
}

.spinner-square > .square {
  width: 17px;
  height: 80px;
  margin: auto auto;
  border-radius: 4px;
}

.square-1 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0s
    infinite;
}

.square-2 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 200ms
    infinite;
}

.square-3 {
  animation: square-anim 1200ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 400ms
    infinite;
}

@keyframes square-anim {
  0% {
    height: 80px;
    background-color: rgb(111, 163, 240);
  }
  20% {
    height: 80px;
  }
  40% {
    height: 120px;
    background-color: rgb(111, 200, 240);
  }
  80% {
    height: 80px;
  }
  100% {
    height: 80px;
    background-color: rgb(111, 163, 240);
  }
}

.searchInput:hover .closeIcon {
  filter: brightness(0) saturate(100%) invert(13%) sepia(26%) saturate(4023%)
    hue-rotate(187deg) brightness(96%) contrast(103%);
}
.searchInput .closeIcon:hover {
  filter: invert(45%) sepia(89%) saturate(5553%) hue-rotate(179deg) brightness(101%) contrast(101%);
}

.dropdownEventCircle {
  margin: 0 auto;
  width: 260px;
  position: relative;
}

.dropdownEventCircle .dropdown-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
}
.dropdown-event-content-circle {
  position: absolute;
  z-index: 10;

  left: 0;
  width: 260px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.07);
}
.dropdown-event-content-circle .item {
  padding: 4px 10px;
  cursor: pointer;
  text-align: left;
}
/* ::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb:horizontal{
  background: #012243;
  border-radius: 10px;
} */

.blur {
  filter: blur(2px)
}

.video-js{
  width: 700px;
  height: auto;
  aspect-ratio: 16/9;
}

.css-1s2u09g-control{
  background-color: #012A50 !important;
  border-color: #143F63 !important;
  border-width: 2px !important;
}
.css-qc6sy-singleValue{
  color: white !important;
  font-family: URW DIN REGULAR !important;
  font-size:14px !important;
}
.css-1pahdxg-control{
  background-color: #012A50 !important;
  border-color: #143F63 !important;
  border-width: 2px !important;
  box-shadow: none !important;
}
.css-1pahdxg-control:hover{
  
  box-shadow: none !important;
}
.css-tlfecz-indicatorContainer{
  color: #143F63 !important;
}
.css-1gtu0rj-indicatorContainer{
  color: #143F63 !important;
}
.css-1gtu0rj-indicatorContainer:hover{
  color: #143F63 !important;
}
.css-1okebmr-indicatorSeparator{
      background-color: #012A50 !important;
}
#react-select-2-listbox{
  background-color: #143F63 !important;
   color: white !important;
  font-family: URW DIN REGULAR !important;
  font-size:14px !important;
}
/* #react-select-2-listbox div:hover{
  background-color: yellow !important;
  color: blue !important;
} */
 video {
  border-radius: 4px;
  }
  input[type=file] {
    cursor:pointer;
}
/* animate text */
.wrapper {
  overflow: hidden;
}

.wrapper > * {
  display: inline-block; /* important */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper .overflow-text {
  transition: 3.3s;
  transition-timing-function: linear;
}

.wrapper:hover .overflow-text {
  animation: animateText 5s infinite alternate;
}

@keyframes animateText {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(100px - 100%));
  }
  100% {
    transform: translateX(2%);
  }
}

.chatCloseIcon:hover {
  filter: brightness(0) saturate(100%) invert(22%) sepia(76%) saturate(4489%) hue-rotate(330deg) brightness(91%) contrast(98%);
}

a {
  color: #88A1AB
}

.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar {
  /* background-color: #021A31; */
  width: 0.5em;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #012A50;
}
.chat {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top:-5px;
  height:20px; */
}
.ball {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  margin: 0 2px;
  animation: 1500ms bounce ease infinite;
}
.blue {
  background-color: #D9D9D9;
  animation-delay: 0.33s;

}
.red {
  background-color: #D9D9D9;
  animation-delay: 0.66s;
}
.yellow {
  background-color: #D9D9D9;
  animation-delay: 0.99s;
}
.green {
  background-color: #34a952;
  animation-delay: 0.99s;
}
@keyframes bounce {
  50% {
    transform: translateY(3px);
  }
}

.drag_button:hover {
   animation: grow 0.3s linear forwards;
   cursor: grabbing;
     
}


@keyframes grow {
  0% {
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -moz-transform: scale(2);
    -o-transform: scale(2);
    -ms-transform: scale(2);
    -webkit-transform: scale(2);
    transform: scale(2);
    background-color: rgba(20, 63, 99, 0.2);
    transform-origin: top left;
  }
}



.drag_button .drag-icon-up{
  transform: translate(12px, -5px);
}
.drag_button:hover .drag-icon-up{
  transform: translate(8px, -8px);
  width: 5px !important;
  filter: brightness(0) invert(1);
}

.drag_button .drag-icon-left{
  transform: translate(-1px, 0px);
}
.drag_button:hover .drag-icon-left{
  transform: translate(-5px, 1px);
  width: 5px !important;
  filter: brightness(0) invert(1);

}
.drag_button .drag-icon-right{
  transform: translate(3px, 0px);
}
.drag_button:hover .drag-icon-right{
  transform: translate(6px, 1px);
  width: 5px !important;
  filter: brightness(0) invert(1);

}

.drag_button .drag-icon-down{
  transform: translate(-10px, 5px);
}
.drag_button:hover .drag-icon-down{
  transform: translate(-7px, 8px);
  width: 5px !important;
  filter: brightness(0) invert(1);

}

.horizontal-scroll {

  overflow-x: scroll;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 5px;
  
}
  
.horizontal-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(20, 63, 99, 1);
  margin-top:4px;
}

.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; 
  height: 470px;
  /* border: 4px dashed #143F63; */
  padding: 1.5rem;
  border-radius: 4px;
  max-width: 900px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin-top: 25px;


}
.grid-wrapper{
  --auto-grid-min-size: 16rem;
			display: grid;
			grid-gap: 2rem;
			grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
}
.hiddenUserWindow{
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: repeat(2, 200px);
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

@keyframes tilt-shaking {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(0eg); }
  75% { transform: rotate(-5deg); }
  100% { transform: rotate(0deg); }
}

/* .grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr); 
  grid-gap: 10px; 
  padding:10px
} */

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two columns */
  grid-auto-rows: minmax(100px, auto); /* Optional: Specify the height of the rows */
  grid-gap: 10px; /* Optional gap between grid items */
  padding:10px

}

.grid-item-first-child {
  grid-column: 1 / span 2; 
}

.abs-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%); /* IE 9 */
    -webkit-transform: translate(-50%,-50%); /* Safari */
    -moz-transform: translate(-50%,-50%); /* Firefox Older versions*/
    -o-transform: translate(-50%,-50%); /* Opera */
    transform: translate(-50%,-50%);
}
/* box fall preloader */
.sq-wrapper {
    display: inline-block;
    position: relative;
    width: 1em;
    height: 1em;
    text-align: center;
}

.sq-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none transparent;
    overflow: hidden;
}

.sq-fill {
    background: none #FD4401;
    width: 100%;
    height: 100%;
}

.loader > div::after {
    content: "LOADING ASSETS";
    position: absolute;
    white-space: nowrap;
    top: 1.875em;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.6;
    letter-spacing: 0.125em;
    font-size: 1.075em;
    color: #fff;
    font-family: 'Dosis', sans-serif;
}

.credits {
  position: absolute;
  left: 1%;
  bottom: 2%;
  color: #011934;
  display: none;
  overflow: hidden;
}



.loading-dot {
  width: 20px;
  height: 20px;
  background-color: #008BCD;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  animation: moveAndChangeColor 1.5s infinite linear alternate;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.25s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes moveAndChangeColor {
  0%,
  100% {
    transform: translateY(0);
    background-color: #008BCD;
  }
  25%,
  75% {
    transform: translateY(-20px);
    background-color: #88A1AB;
  }
}

@keyframes moveAndChangeColor {
  0%,
  100% {
    transform: translateY(0);
    background-color: #008BCD;
  }
  50% {
    transform: translateY(-20px);
    background-color: #88A1AB;
  }
}

